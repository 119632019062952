import { useEffect } from 'react';
import { HashRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Header from './components/Header/Header'; 
import Footer from './components/Footer/Footer'; 
import  Company  from './pages/Company/Company'
import  GetStarted  from './pages/GetStarted/GetStarted'
import  Home  from './pages/Home/Home'
import  MerchantProcessingSolutions  from './pages/MerchantProcessingSolutions/MerchantProcessingSolutions'

const ScrollToTop = () => {
    // Fix window load position bug
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }

const PageRoutes = () => {
    return (
        <Router>
            <Header /> 
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/company" element={<Company />} />
                <Route path="/getstarted" element={<GetStarted />} />
                <Route path="/merchantprocessingsolutions" element={<MerchantProcessingSolutions />} />
                <Route path="*" element={
                  <> 
                    <h1 style={{textAlign: 'center'}}>404 Not Found</h1>
                  </>
                } />
            </Routes>
          <Footer />
        </Router>
      );
}

export default PageRoutes;